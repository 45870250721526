import { computed } from 'vue';
import { useStore } from 'vuex';
import { useNuxtApp, useRuntimeConfig, useRequestHeader, useCookie } from '#imports';
import { useIntranet } from '@/composables/useIntranet';
import Cookies from 'js-cookie';
import isEmpty from 'lodash/isEmpty';

export const usePageContent = () => {
  const app = useNuxtApp();
  const isHydrating = computed(
    () => !!import.meta.client && !!useNuxtApp()?.isHydrating && !!useNuxtApp()?.payload?.serverRendered,
  );
  const { isIntranet } = useIntranet();
  const { $axios } = useNuxtApp();
  const store = useStore();
  const config = useRuntimeConfig();

  const isPageBuilder = computed(() => !!useRequestHeader('referer')?.includes('page-builder'));
  const secondaryOrgId = computed(() => store.getters.getSecondaryOrgId);
  const langCode = computed(() => {
    const cookieHeader = useRequestHeader('cookie');
    return !isEmpty(cookieHeader)
      ? `; ${cookieHeader}`.split('; langCode=').pop().split(';').shift()
      : Cookies.get('langCode');
  });

  const handlePagePreviewAuth = async (to) => {
    const id = to.params.id || to.query.id;
    if (!id) throw new Error('No page id found');

    const { data } = await $axios.get(`${config.CMS_PREVIEW_URL}/${id}`);

    app.runWithContext(() => {
      store.commit('setPageConfig', data);
    });
  };

  const fetchFullSitemap = async () => {
    try {
      const { data = null } = await $axios.get(`/api/v4/o/${store.state.secondaryOrgId}/cms/sitemap`);
      app.runWithContext(() => {
        store.commit('setFullSiteMap', data);
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handlePageData = async (to, from) => {
    if (isHydrating.value) return;
    if (isPageBuilder.value) return await handlePagePreviewAuth(to);
    if (to.query.openSearch !== from.query.openSearch) return;

    const slugFromRoute = to.params.slug;
    const authCookie = isIntranet.value ? useCookie('auth-token') : null;
    const basePagesUrl = `api/v4/o/${secondaryOrgId.value}/cms/pages`;
    const pageUrl = `${basePagesUrl}/${slugFromRoute}?locale=${langCode.value}`;
    const intranetBasePagesUrl = `api/v6/secondary_organizations/${secondaryOrgId.value}/departments/pages`;
    const intranetPageUrl = `${intranetBasePagesUrl}/${slugFromRoute}?locale=${langCode.value}`;
    const { data } = await $axios.get(isIntranet.value ? intranetPageUrl : pageUrl, {
      cache: { ignoreCache: false },
      headers: authCookie ? { Authorization: authCookie.value } : {},
    });

    if (!!store?.state?.featureFlags?.cms_page_renderer_package_enabled && !store?.state?.fullSiteMap) {
      await fetchFullSitemap();
    }

    app.runWithContext(() => {
      const pageConfig = {
        ...(isIntranet.value ? data.data : data),
        slug: slugFromRoute,
        secOrgId: secondaryOrgId.value,
        langCode: langCode.value,
      };

      const isPublished = (pageConfig.status || pageConfig.data.status) === 'published';
      if (!isPublished) return new Error('Page not found');

      store.dispatch('setPageData', pageConfig);
    });
  };

  return {
    handlePagePreviewAuth,
    handlePageData,
  };
};
